import React from 'react';

import ChromeRating from 'core/modules/Partials/ChromeRating/ChromeRating';
import starsImage from 'org/assets/images/icons/stars.svg';

function ButtonScrollingModalContent({
  redirectToChromeStore, renderedLogos, storeCount, buttonContentRef,
}) {
  return (
    <div className="mn_buttonContent" ref={buttonContentRef}>
      <div className="mn_buttonBlock mn_buttonBlockOne">
        <div className="mn_motto">The Rapid Rewards Shopping<sup>&reg;</sup> Button</div>
        <h2 id="mn_dialogTitle">Earning points couldn't be easier.</h2>
        <p id="mn_dialogDescription">
          On average, Members who use the Rapid Rewards Shopping button browser<br />
          extension earn <strong>twice as many points</strong> as Members who do not use the button.*
        </p>
        <button className="mn_button" data-number="1" onClick={redirectToChromeStore}>Add to browser</button>
        <ChromeRating chromeText="Chrome Web Store rating" storeRatingImage={starsImage} />
        <div className="mn_earningNote">
          *Twice as many points earnings based on average button user spend. Individual earnings may vary.
        </div>
      </div>
      <div className="mn_buttonBlock mn_buttonBlockTwo">
        <div className="mn_buttonOverlayMerchantLogosWrap">
          <div className="mn_buttonOverlayMerchantLogos">
            {renderedLogos}
          </div>
        </div>
        <div className="mn_motto">Never miss a point with the button.</div>
        <h2>Earn points at {storeCount}+ stores with the button.</h2>
        <p>
          Notifications and automatic coupon alerts through the Rapid Rewards Shopping button<br />
          help you save money and earn points on the shopping you're already doing.
        </p>
        <button className="mn_button" data-number="2" onClick={redirectToChromeStore}>Discover the button</button>
      </div>

      <div className="mn_buttonBlock mn_buttonBlockThree">
        <h2>How it works</h2>
        <div className="mn_hiwHints">
          <div>
            <h3>Install in seconds</h3>
            <p>Download the free browser extension in just two clicks.</p>
          </div>
          <div>
            <h3>Shop as usual</h3>
            <p>Shop at {storeCount}+ stores &#8212; the button will notify you when points are available.</p>
          </div>
          <div>
            <h3>Earn points</h3>
            <p>Click the notification to activate your earnings and rack up Rapid Rewards points.</p>
          </div>
        </div>
        <button className="mn_button" data-number="3" onClick={redirectToChromeStore}>Install now</button>
      </div>

      <div className="mn_buttonBlock mn_buttonBlockFour">
        <h2>Get time saving perks all in one place.</h2>
        <p>
          The button gives you quick access to extra points, bonus offers and account information.<br />
          Plus, the button automatically applies coupons directly to your cart.
        </p>
        <button className="mn_button" data-number="4" onClick={redirectToChromeStore}>Start earning more</button>
        <ChromeRating chromeText="Chrome Web Store rating" storeRatingImage={starsImage} />
      </div>
    </div>
  );
}

export default ButtonScrollingModalContent;
