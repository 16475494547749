import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EmailOptInSubscribeForm from 'core/modules/Forms/EmailOptInSubscribeForm';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { selectSiteUrl, selectFormSecurityToken } from 'core/selectors/app';
import Modal from 'core/modules/Modal/Modal';
import { useModal } from 'core/modules/Modal/ModalProvider';

import spinner from 'org/assets/images/favorites/spinner.svg';

import { triggerFirstFavoriteModalIsViewed } from './actions';

import './FirstFavoriteModal.scss';

function FirstFavoriteModal() {
  const dispatch = useDispatch();
  const siteUrl = useSelector(selectSiteUrl);
  const securityToken = useSelector(selectFormSecurityToken);
  const [hasUserClickedTurnOnButton, setHasUserClickedTurnOnButton] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showRequestProgress, setShowRequestProgress] = useState(false);
  const { closeModal } = useModal();

  const emailOptions = {
    email_favorites_optin: 1,
  };

  useEffect(() => {
    dispatch(triggerFirstFavoriteModalIsViewed());
  }, []);

  const onClickHandler = () => {
    setHasUserClickedTurnOnButton(true);
  };

  const customOnUploadProgress = () => {
    setIsFormSubmitted(false);
    setShowRequestProgress(true);
  };

  const callbackAfterSubmitForm = () => {
    setIsFormSubmitted(true);
    setShowRequestProgress(false);
  };

  const noThanksClickHandler = () => {
    closeModal();
  };

  const renderInitialView = () => (
    <>
      <p>Turn on alerts and we'll email when your favorite stores offer extra points.</p>
      <button
        className="mn_button mn_favoriteTurnOnAlerts"
        onClick={onClickHandler}
      >
        Turn on alerts
      </button>
      <button className="mn_favoriteNoThanks" onClick={noThanksClickHandler} aria-label="no thanks">
        No thanks, I don't want to know about extra points offers.
      </button>
    </>
  );

  const renderSuccessSubmitView = () => (
    <div className="mn_favoriteAlertsActivated">
      <p>
        Favorite alerts are now on. Visit{' '}
        <a className="mn_myAccountLink" href={`${siteUrl}/ai____.htm`}>My Account</a>{' '}
        to manage your alert preferences at any time.
      </p>
      <a href={`${siteUrl}/favorites____.htm`} className="mn_button mn_viewFavorites">Favorite more stores</a>
    </div>
  );

  const renderRequestProgressView = () => (
    <div className="mn_favoritesRequestProgressWrap">
      <div className="mn_favoritesRequestProgress">
        <img src={spinner} alt="loading..." />
      </div>
    </div>
  );

  return (
    <Modal className="mn_firstFavoriteModal">
      {hasUserClickedTurnOnButton &&
        <EmailOptInSubscribeForm
          useAjax
          siteUrl={siteUrl}
          cbUrl={`${siteUrl}?act=email`}
          fcbUrl={`${siteUrl}`}
          securityToken={securityToken}
          form="UpdateFavoriteAlerts"
          formAction="auth.php"
          emailOptions={emailOptions}
          callback={callbackAfterSubmitForm}
          customOnUploadProgress={customOnUploadProgress}
        />
      }

      <h2>Success! You've picked a favorite store.</h2>

      {isFormSubmitted && !showRequestProgress && renderSuccessSubmitView()}
      {!isFormSubmitted && !showRequestProgress && renderInitialView()}
      {showRequestProgress && renderRequestProgressView()}
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><FirstFavoriteModal {...props} /></ComponentErrorBoundary>;
