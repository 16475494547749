import { all, call } from 'redux-saga/effects';
import values from 'lodash/values';
import coreSagas from 'core/sagas';
import searchFormSaga from 'core/modules/SearchForm/sagas';
import favoritesSaga from './favorites';

const combinedSagas = {
  ...coreSagas,
  searchFormSaga,
  favoritesSaga,
};

export default function* rootSaga() {
  yield all(values(combinedSagas).map(saga => call(saga)));
}
