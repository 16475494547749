import React from 'react';

/* Images */
import hiwClickImage from 'org/assets/images/hiw/hiw_home_click.png';
import hiwEarnImage from 'org/assets/images/hiw/hiw_home_earn.png';
import hiwExploreImage from 'org/assets/images/hiw/hiw_home_explore.png';
import hiwShopImage from 'org/assets/images/hiw/hiw_home_shop.png';
import starsImage from 'org/assets/images/icons/stars.svg';
import checkmarkSVG from 'org/assets/images/icons/checkmark.svg';
import homeButtonImage from 'org/assets/images/home_button.png';
import favoriteIconSvg from 'org/assets/images/icons/favorite.inline.svg';

/* Components */
import ButtonScrollingModalContent
  from 'org/modules/Modals/ButtonScrollingModal/components/ButtonScrollingModalContent';

export const infoBar = {
  defaults: {
    infoBarAccordion: {
      couponCodeLanguage: 'Shop with code:',
      dateFormat: 'MMM. DD, YYYY',
    },
  },
};

export const trendingAndSeasonalOffers = {
  defaults: {
    getTitle: () => ('More ways to earn points'),
  },
};

export const marketingMerchantsHome = {
  defaults: {
    secTitlePill: 'Extra points',
  },
};

export const quickLinksTray = {
  defaults: {
    extraRewardsLanguage: 'Extra points',
  },
};

export const searchBar = {
  defaults: {
    getPlaceholder: (activeMQ) => (['XL', 'XXL'].includes(activeMQ) ? 'Find a store or product' : ''),
  },
};

export const searchForm = {
  defaults: {
    searchButtonClassName: 'mn_SVGIconSearch',
    shouldShowResultLinkOutside: true,
    shouldUsePopUpOnMobile: true,
  },
};

export const navPopularStores = {
  defaults: {
    secTitle: 'Shop by popular stores',
  },
};

export const navCategoryFlyout = {
  defaults: {
    shouldShowMerchantName: true,
  },
};

export const recentStores = {
  defaults: {
    viewData: {
      footerCTATitle: 'View all recent stores',
    },
  },
};

export const headerExtraRewards = {
  defaults: {
    viewAllLanguage: 'View all stores offering extra points',
  },
};

export const featuredDeals = {
  defaults: {
    getViewAllLinkText: (isMobileMQ) => (
      isMobileMQ ? 'See all deals' : 'View all deals'
    ),
  },
};

export const favoritesIcon = {
  defaults: {
    favoriteIconViewBox: '0 0 22 19',
    FavoriteIconSvg: favoriteIconSvg,
  },
};

export const searchModal = {
  defaults: {
    placeholderLanguage: '',
  },
};

export const bonusOffers = {
  defaults: {
    usePeriod: true,
    seeDetailPrefix: 'Terms & conditions apply. ',
    useDateInBonusTag: true,
  },
};

export const earningsSnapshot = {
  defaults: {
    bonusTitle: 'Rapid rewards shopping',
  },
};

export const navCategoryFeatured = {
  defaults: {
    rebateOptions: {
      showRebateInline: true,
    },
  },
};

export const merchantOffer = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: false,
      },
    },
    getCopyButtonText: (isMobileMQ) => (
      isMobileMQ ? 'Copy' : 'Copy code'
    ),
    getExpiresText: (isMobileMQ) => (
      isMobileMQ ? 'Valid thru' : 'Offer valid through'
    ),
    getCopyButtonDisclaimerText: (isMobileMQ) => (
      isMobileMQ ? 'Copy and paste this code at checkout.' : 'Copy and paste this code and use at checkout.'
    ),
    useRebateInlineOnMediaQueriesOnly: ['M', 'L', 'XL', 'XXL'],
    dateFormat: 'MMM. D, YYYY',
  },
};

export const topStores = {
  h: {
    api: {
      params: {
        limit: 24,
      },
    },
  },
};

export const adBlockWarning = {
  defaults: {
    getWarningMessage: () => (
      `The use of ad blockers may prevent you from earning Rapid Rewards points on your shopping.
      While shopping with Rapid Rewards Shopping, turn off any ad blockers or use a different browser
      to ensure you earn points as expected.`
    ),
  },
};

export const letterboxTOF = {
  defaults: {
    dateFormat: 'MMM. DD, YYYY.',
    getCouponCodeLabelText: () => 'Shop with code: ',
    rebateOptions: {
      showAdditionalElevationPrefix: true,
    },
    carousel: {
      breakpoints: {
        '(min-width: 610px)': {
          hasDots: false,
          hasPlayButton: true,
          getPlayElement: () => null,
          getPauseElement: () => null,
        },
      },
    },
    shouldShowPlayPauseButton: true,
  },
};

export const howItWorks = {
  defaults: {
    hiwItems: [
      {
        image: hiwExploreImage,
        title: 'Explore',
        description: ({ storeCount }) => (
          <>Explore over {storeCount} stores, exclusive offers and bonus promotions, all in one place.</>
        ),
      },
      {
        image: hiwClickImage,
        title: 'Click',
        description: () => (
          <>Click on any store or offer and you'll be taken to the store's website.</>
        ),
      },
      {
        image: hiwShopImage,
        title: 'Shop',
        description: () => (
          <>Shop like you normally do and pay with any credit card — no special card needed.</>
        ),
      },
      {
        image: hiwEarnImage,
        title: 'Earn',
        description: () => (
          <>After making a purchase, points earned will be added to your Rapid Rewards<sup>&reg;</sup> account.</>
        ),
      },
    ],
    checkShouldShowVideoButton: (userIsIdentified, activeMQ, isPersonalizedMhp) => {
      if (isPersonalizedMhp) return true;
      return !userIsIdentified;
    },
    getSectionTitleText: (userIsIdentified, earnType, isPersonalizedMhp) => {
      if (isPersonalizedMhp) return 'Start earning points today!';
      return userIsIdentified ? 'How it works' : 'Sign in for free to start earning points today.';
    },
    videoId: 'vjKfphbFwpk',
    videoPlayer: 'youtube',
    videoTitle: 'Southwest Rapid Rewards Shopping How it Works video',
  },
};

export const testimonials = {
  defaults: {
    shouldUseRewardCountValue: false,
    testimonialsCopy: [
      '"Heck yes I\'ll take SW points while buying stuff I need anyway! It\'s the best."',
      '"SW is the best. Not only do I love to shop but I get rewarded ' +
      'with SW points as well! I wish I knew about this years ago."',
      '"What is not to love? I do most of my shopping online, and now as an added bonus I get SW points!"',
    ],
    checkShouldShowIconInCarousel: (activeMQ) => (['XS', 'S'].includes(activeMQ)),
    checkShouldShowIconInSlide: (activeMQ) => (['M', 'L', 'XL', 'XXL'].includes(activeMQ)),
  },
};

export const merchantLogos = {
  h: {
    useCarouselOnMediaQueriesOnly: ['XS', 'S', 'M', 'L'],
    carousel: {
      align: 'center',
    },
  },
};

export const stickyCtaBar = {
  defaults: {
    topOffsetSelector: '.mn_primaryHeroWrap',
    useStickyBehaviorOnMediaQueriesOnly: ['XL', 'XXL'],
    viewOptions: {
      title: 'Turn your shopping into points today.',
      buttonLanguage: 'Sign in to shop',
    },
  },
};

export const onboardingWelcome = {
  defaults: {
    getWelcomeText: (storeCount) => (
      <>
        Here are a few tips for earning the most Rapid Rewards<sup>&reg;</sup>{' '}
        points when you shop at {storeCount}+ stores.
      </>
    ),
  },
};

export const onboardingFavorites = {
  defaults: {
    shouldSubscribeMemberOnFavorites: true,
    favoritesHeading: 'Pick five or more favorite stores.',
    turnOffText: 'By adding a store to your favorites and selecting next, you are opting in to receive email alerts.',
  },
};

export const onboardingButton = {
  defaults: {
    viewData: {
      getButtonHeader: () => (
        <>Activate points directly at store sites with the Rapid Rewards Shopping<sup>&reg;</sup> button.</>
      ),
      rewardsType: () => (
        <>points</>
      ),
      customThirdBulletText: 'Discover new stores that offer points/$1',
      usePeriod: true,
      starsSvg: starsImage,
    },
  },
};

export const onboardingEmail = {
  defaults: {
    shouldOptInBeCheckedByDefault: false,
  },
};

export const headerOnboardingCongratulations = {
  defaults: {
    image: checkmarkSVG,
  },
};

export const emailOptInSubscribeModal = {
  defaults: {
    emailOptions: {
      email_news_optin: 1,
      email_odotw_optin: 1,
      email_promo_optin: 1,
      email_global_optout: 0,
    },
  },
};

export const buttonScrollingModal = {
  defaults: {
    components: {
      ButtonScrollingModalContent,
    },
  },
};

export const homeButtonBenefits = {
  defaults: {
    buttonHeader: 'Earn points with the push of a button.',
    buttonDescription: 'Add the button browser extension for Chrome and you\'ll '
    + 'get notifications while shopping so you never forget to earn points. Plus, you can:',
    buttonCopy: [
      'Automatically apply coupons at checkout.',
      'Find new stores offering points/$1 and compare rates in search results.',
      'Quickly access extra points, bonus offers, and account information.',
    ],
    buttonImage: homeButtonImage,
  },
};

export const popularOffers = {
  defaults: {
    carousel: {
      isInverted: true,
      breakpoints: {
        m: { isInverted: false },
      },
    },
  },
};
